//var url = "ws://47.118.44.107:8283"
var url = "ws://47.118.44.107:8280"
function getSocket(actionName, callback){
    let socket;

    if (typeof (WebSocket) === 'undefined') {
        console.log('您的浏览器不支持WebSocket');
    } else {
        console.log('您的浏览器支持WebSocket');

        // 初始化 WebSocket 对象，指定要连接的服务器地址与端口建立连接
        socket = new WebSocket(url);


        var sendData = JSON.stringify({
            "action" : actionName,
            "m_id" : '1'
        });
        // 打开事件

        socket.onopen = function() {
            console.log('Socket 已连接');
            socket.send(sendData);
        };
        setTimeout(function(){
            socket.send(sendData);
        },3000)
        // setInterval(function() {
        //     socket.send(sendData);
        // }, 3000)

        // 获得消息事件
        socket.onmessage = function(msg) {
            // 发现消息进入, 开始处理前端触发逻辑
            let res = JSON.parse(msg.data);
            if(res.result.msg.search(actionName) != -1){
                // console.log(222, res)
                callback(res, socket)
            }
            
            // callback(msg, socket);
        };

        // 关闭事件
        socket.onclose = function() {
            console.log('Socket 已关闭');
        };

        // 发生了错误事件
        socket.onerror = function() {
            console.log('Socket 发生了错误,请刷新页面');
            // 此时可以尝试刷新页面
        };
    }
}

var getAjax = (option) => {
    let Method = option.method || "GET"
    let data = option.data || {} // {pageNum:1, pageSize:10}
    let url = option.url   // http://localhost:3000/api/film/getList

    // let port = "https://yyd-apis.zjzxsl.com"
    let port = window.host
    var portUrl = port + url
    if(Method == 'GET'){
        let str = "?"
        for(let key in data){
            str += key + "=" + data[key] + "&" // pageNum=1pageSize=10
        }
        // 切除掉最后一个 &
        str = str.slice(0,str.length-1)
        url +=str
    }
    // 将参数拼接到URL上
    // console.log(portUrl)
    // 1.创建ajax对象
    let xhr = new XMLHttpRequest()
    // 2.建立连接
    xhr.open(Method, portUrl,true)
    // 3.发送请求
    xhr.send()
    // 4.监听数据回来
    xhr.onreadystatechange=function(){
        // xhr.readyState === 4 意味这服务器返回数据
        // xhr.status == 200 服务器返回正确的数据
        if( xhr.readyState === 4 && xhr.status == 200 ){

            let res =  JSON.parse(xhr.responseText)
            option.success(res)
        }
    }
};
let timestamp = () => Date.parse(new Date())/1000;

export {
    getSocket,
    getAjax,
    timestamp,
};