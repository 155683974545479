window.echartsShow = (showdata,_this, name) => {
  show(showdata,_this, name)
};

function show(showdata, _this, name){
  let _name = name
  _this.$store.state.shaoxingone=false;
  _this.$store.state.street=false;
  _this.$store.state.streetone=false;
  _this.$store.state.peopleone=false;
  _this.$store.state.peopletwo=false;
  _this.$store.state.peoplethree=false;
  _this.$store.state.stadiumone=false;
  _this.$store.state.stadiumtwo=false;
  _this.$store.state.stadiumthree=false;
  _this.$store.state.touristwalk=false;
  _this.$store.state.familyfitness=false;
  _this.$store.state.eventsone=false;
  _this.$store.state.sportindustry=false;
  _this.$store.state.association=false;
  _this.$store.state.socialinstructor=false;
  _this.$store.state.athlete=false;
  _this.$store.state.nationalfitness=false;
  _this.$store.state.outdoorpain=false;
  _this.$store.state[showdata]=true;
}

export {            //关键 导出
    show,
}